<template>
  <div class="read_con" v-loading="options.loading">
    <p class="read_on_title">
      <span>{{ options.title || "- -" }}</span>
    </p>
    <!--        <div v-html="options.content" class="books_content"></div>-->
    <div class="books_content">
      <div
        class="section-con"
        v-for="(item, index) in this.getCommentHtml"
        :key="index"
      >
        <div
          class="section"
          v-html="item"
          :style="{ fontSize: size + 'px' + '!important' }"
        ></div>
        <el-popover
          placement="top-end"
          v-if="item.replace(/<\/?.+?\/?>/g, '') !== ''"
          width="800"
          trigger="click"
        >
          <div class="section_comment_content">
            <div class="list">
              <div class="no-debunk" v-if="!getParagraph(index).length">
                还没有吐槽哟，赶紧吐槽吧～
              </div>
              <div
                class="item"
                v-for="item in getParagraph(index)"
                :key="item.id"
              >
                <div class="top-content">
                  <img :src="item.avatar" :onerror="deaultImage" alt="" />
                  <a href="javascript:;">{{ item.nickname }}</a>
                  <div class="isVip" v-if="item.is_vip">VIP</div>
                  <div class="time">{{ item.cre_time }}</div>
                </div>
                <div class="comment-content">{{ item.content }}</div>
                <div class="zan">
                  <div
                    @click="thumbsUpAction(item, 1)"
                    :class="item.like_info.my_like_status === 1 ? 'active' : ''"
                  >
                    <i class="iconfont icon-zan"></i>赞({{
                      item.like_info.like_num
                    }})
                  </div>
                  <div
                    @click="thumbsUpAction(item, 2)"
                    :class="
                      item.like_info.my_like_status === 2 ? 'active2' : ''
                    "
                  >
                    <i class="iconfont icon-cai"></i>黑({{
                      item.like_info.black_num
                    }})
                  </div>
                </div>
              </div>
            </div>
            <div class="commet-con">
              <el-input
                v-model="debunkText[index]"
                :style="{ width: '86%' }"
                placeholder="吐槽这一段"
                maxlength="50"
                show-word-limit
                size="small"
              ></el-input>
              <el-button
                size="small"
                type="primary"
                @click="debunkAction(index)"
                >发送槽点</el-button
              >
            </div>
          </div>
          <div
            slot="reference"
            :class="
              'comment-btn' + (getParagraph(index).length ? ' active' : '')
            "
          >
            {{
              getParagraph(index).length > 99
                ? "99+"
                : getParagraph(index).length
            }}
          </div>
        </el-popover>
      </div>
    </div>
    <!-- @copy.native.capture.prevent='xxx' -->
    <div v-if="options.is_order === 0" class="vip_box">
      <p>本章节为付费章节，需购买后才能阅读</p>
      <div flex center>
        <p
          class="btn"
          flex
          column
          center
          align
          @click="message_box('购买本章')"
        >
          <span>购买本章</span>
          <span>{{ options.coins }}书币</span>
        </p>
        <p
          class="btn"
          flex
          column
          center
          align
          @click="message_box('订阅全部章节')"
        >
          <span>订阅全部章节</span>
          <span>{{ options.all_subscribe_coins }}书币</span>
        </p>
      </div>
    </div>
    <div :class="!is_popping ? 'read_unpop' : 'read_pop'" flex center align>
      <div class="read_popbox">
        <div>
          <span>购买章节</span>
          <span
            class="iconfont icon-guanbi read_close"
            @click="message_box"
          ></span>
        </div>
        <div flex column>
          <p>
            {{ is_popping }}将会消耗
            {{
              is_popping === "订阅全部章节"
                ? options.all_subscribe_coins
                : options.coins
            }}书币
          </p>
          <p>当前余额为 {{ options.balance }}书币</p>
        </div>
        <div flex center align>
          <button key-button type="button" v-if="!have_money" @click="up_money">
            去充值
          </button>
          <button key-button type="button" v-if="have_money" @click="pay_money">
            支付
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { pay_coin, pay_all } from "@/https/read_books.js";
import {
  books_review,
  add_review,
  thumbsUpParagraph,
} from "@/https/read_books.js";

export default {
  data() {
    return {
      is_popping: false,
      // 用户剩余的书币
      remain_money: "100",
      // 该章节需要的书币数量
      need_money: "10",
      have_money: false,
      defaultAvator: require("./../../assets/images/default_header.png"),
      deaultImage:
        'this.src="' +
        require("./../../assets/images/default_header.png") +
        '"',
      debunkText: [], //吐槽内容
      commentList: [], //段落评论列表
      size: 18,
    };
  },
  props: {
    options: {
      type: Object,
    },
  },
  created() {
    let size = sessionStorage.getItem("font_size");
    if (size) {
      this.size = Number(size);
    } else {
      sessionStorage.setItem("font_size", 18);
    }
  },
  mounted() {
    this.getCommentList();
  },
  watch: {
    aviod_font_size(n, v) {
      this.size = Number(n);
    },
    "$route.query"() {
      this.getCommentList();
    },
  },
  computed: {
    ...mapGetters(["aviod_font_size"]),
    //将章节内容转换为可评论的数组
    getCommentHtml() {
      let res = [];
      let content = "";
      if (!this.options.content) {
        return this.options.content;
      }
      if (
        this.options.content.indexOf("<p") === 0 ||
        this.options.content.endsWith("</p>")
      ) {
        if (this.options.content.lastIndexOf("<p") === 0) {
          content = this.options.content.replace(/<p>/g, "");
          content = content.replace(/<\/p>/g, "");
          content = content.replace(/<br\/>/g, "<br />");
          res = content.split("<br />");
          res = res.map((item) => {
            return `${item}<br />`;
          });
        } else {
          res = this.options.content.split("</p>");
          res = res.map((item) => {
            return item + "</p>";
          });
        }
      } else {
        content = this.options.content.replace(/<br\/>/g, "<br />");
        res = content.split("<br />");
        res = res.map((item) => {
          return `${item}<br/>`;
        });
      }
      return res;
    },
    getParagraph() {
      return function (index) {
        return this.commentList.filter((item) => item.paragraph - 1 === index);
      };
    },
  },
  methods: {
    message_box(text) {
      this.have_money =
        Number(this.options.balance) >= this.options.coins ? true : false;
      this.is_popping = this.is_popping ? false : text;
    },
    up_money() {
      let mine_menu = {
        link: "/recharge",
        name: "充值中心",
        id: 7,
        icon: "icon-caiwuzhongxin",
      };
      this.$store.commit("set_mine_menu", mine_menu);
      this.$router.push("/recharge");
      // 去个人中心充值界面
    },
    pay_money() {
      if (this.is_popping === "订阅全部章节") return this.payAll();
      //支付界面
      let params = {
        books_id: Number(this.$route.query.id),
        page: Number(sessionStorage.getItem("section_page")),
      };
      pay_coin(params).then((res) => {
        if (res.code === 0) {
          this.$message.success("购买成功");
          // this.$router.go(0)
          window.location.reload();
        } else {
          this.$message.warning(res.msg);
        }
      });
    },
    //一键订阅所有章节
    payAll() {
      pay_all({
        book_id: Number(this.$route.query.id),
      }).then((res) => {
        if (res.code === 0) {
          this.$message.success("购买成功");
          // this.$router.go(0)
          window.location.reload();
        } else {
          this.$message.warning(res.msg);
        }
      });
    },
    getCommentList() {
      books_review({
        id: this.$route.query.id,
        section_id: this.$route.query.page || 1,
      }).then((res) => {
        if (res.code === 0) {
          this.commentList = (res.data && res.data.review_content) || [];
        }
      });
    },
    debunkAction(index) {
      add_review({
        books_id: this.$route.query.id,
        content: this.debunkText[index],
        section_id: this.$route.query.page || 1,
        paragraph: index + 1,
      }).then((res) => {
        if (res.code === 0) {
          this.debunkText[index] = "";
          this.$message.success("吐槽成功");
          this.getCommentList();
        }
      });
    },
    thumbsUpAction(item, type) {
      if (item.like_info.my_like_status) {
        type = 0;
      }
      thumbsUpParagraph({
        comment_id: item.id,
        type,
      }).then((res) => {
        if (res.code === 0) {
          this.getCommentList();
        }
      });
    },
  },
};
</script>
<style lang="scss">
.read_con {
  width: 100%;
  height: 100%;

  & > div:nth-of-type(1) {
    margin-bottom: 40px;
    font-size: 14px;
  }

  .read_on_title {
    font-size: 20px;
    margin-bottom: 60px;
    display: flex;
    flex-wrap: nowrap;

    span:nth-child(2) {
      padding-left: 26px;
    }
  }

  .vip_box {
    text-align: center;
    border-top: 1px solid rgba(153, 153, 153, 1);
    padding: 30px 0 0;

    & p:nth-of-type(1) {
      font-size: 18px;
      color: #666;
    }

    & p.btn {
      margin: 40px auto 0;
      background-color: #f56c6c;
      color: #ffffff;
      width: 200px;
      height: 80px;
      border-radius: 5px;
      cursor: pointer;

      & span:nth-of-type(1) {
        // font-weight: bold;
      }

      & span:nth-of-type(2) {
        font-size: 14px;
        margin-top: 5px;
      }
    }

    & p.btn:hover {
      opacity: 0.8;
    }
  }

  .read_pop {
    width: 100vw;
    height: 100vh;
    background-color: rgba(47, 79, 79, 0.6);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 301;

    .read_popbox {
      width: 450px;
      height: 230px;
      background-color: #fff;
      border-radius: 2px;

      & div:nth-of-type(1) {
        color: #333;
        // font-weight: bold;
        margin: 0;
        position: relative;
        padding: 12px 20px;

        &span:nth-of-type(1) {
          line-height: 50px;
        }

        .read_close {
          position: absolute;
          top: 0;
          right: 0;
          padding: 5px;
          color: #999;
          // font-weight: bold;
          font-size: 17px;
          cursor: pointer;
        }

        .read_close:hover {
          color: #409eff;
        }
      }

      & div:nth-of-type(2) {
        text-align: center;
        height: 120px;
        width: 100%;
        border-bottom: 1px solid rgba(228, 228, 228, 1);
        justify-content: flex-end;

        & p:nth-of-type(1) {
          font-size: 18px;
          color: #333;
          line-height: 51px;
        }

        & p:nth-of-type(2) {
          font-size: 16px;
          color: #999;
          line-height: 49px;
          margin-bottom: 8px;
        }
      }

      & div:last-of-type {
        height: 60px;
        font-size: 14px;
        color: #333;

        button {
          height: 32px;
          width: 100px;
        }

        & button:nth-of-type(1) {
          border: 1px solid rgba(228, 228, 228, 1);
        }

        & button:nth-of-type(1):hover {
          border-color: rgba(64, 158, 255, 0.2);
          color: rgba(64, 158, 255, 0.8);
          background-color: rgba(64, 158, 255, 0.1);
        }

        & button:nth-of-type(2) {
          background-color: #409eff;
          margin-left: 60px;
          color: #fff;
        }

        & button:nth-of-type(2):hover {
          opacity: 0.8;
        }
      }
    }
  }

  .read_unpop {
    display: none;
  }
}

.books_content {
  position: relative;
  padding: 5px 0;
  line-height: 2.2em;
  letter-spacing: 1px;
  &:after {
    content: "";
    display: block;
    position: absolute;
    right: -30px;
    top: 0;
    width: 1px;
    height: 100%;
    background-color: #999999;
  }
  .section-con {
    position: relative;
    padding: 5px 0;
    line-height: 36px;
    letter-spacing: 1px;
  }
  .comment-btn {
    width: 40px;
    height: 40px;
    border: 2px solid #fff;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    right: -49px;
    font-size: 20px;
    z-index: 10;
    cursor: pointer;
    color: #fff;
    text-align: center;
    line-height: 36px;
    background: #999999;
    &.active {
      background-color: #d63b3b;
    }
    transform: translateY(-50%) scale(0.5);
    transform-origin: 50% 50%;
  }
  p {
    text-indent: 2em;
  }
}
.section_comment_content {
  .no-debunk {
    color: #999;
    line-height: 4em;
    text-align: center;
  }
  .list {
    max-height: 380px;
    padding: 0 25px;
    overflow-y: hidden;
    &:hover {
      overflow-y: auto;
    }
    .item {
      margin-bottom: 15px;
      border-bottom: 1px solid #eeeeee;
      .top-content {
        display: flex;
        align-items: center;
        img {
          width: 46px;
          height: 46px;
          border-radius: 50%;
        }
        a {
          margin: 0 10px;
          font-size: 16px;
          color: #4398cc !important;
        }
        .isVip {
          width: 34px;
          line-height: 20px;
          text-align: center;
          color: #fff;
          background-color: #fc6c28;
          border-radius: 5px;
        }
        .time {
          flex-grow: 2;
          color: #999999;
          text-align: right;
        }
      }
      .comment-content {
        margin: 5px 0;
        padding: 0 34px;
        color: #666666;
      }
      .zan {
        margin-bottom: 15px;
        text-align: right;
        div {
          display: inline-block;
          color: #999999;
          margin: 0 20px;
          cursor: pointer;
          &.active {
            color: #f9c647;
            i {
              color: #f9c647;
            }
          }
          &.active2 {
            color: #f56c6c;
            i {
              color: #f56c6c;
            }
          }
        }
        i {
          margin-right: 7px;
          color: #999999;
        }
      }
    }
  }
  .commet-con {
    padding: 0 25px;
    text-align: right;
    button {
      margin-left: 21px;
    }
    & > * {
      vertical-align: top;
    }
  }
}
</style>
