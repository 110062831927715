<template>
  <div class="read_left">
    <div class="menu_box">
      <p flex column @click.stop="show_catlog">
        <i class="iconfont icon-mulu2"></i>
        <span>目录</span>
      </p>
      <p
        flex
        column
        align
        @click.stop="add_brack"
        :class="!is_collection ? '' : 'brack'"
      >
        <i class="iconfont icon-shujia" v-if="!is_collection"></i>
        <span v-text="!is_collection ? '加入书架' : '已在书架'"></span>
      </p>
      <p flex column @click="book_info">
        <i class="iconfont icon-shu1"></i>
        <span>作品详情</span>
      </p>
      <p flex column @click.stop="font_type = true">
        <i class="iconfont icon-daxiaoxie1"></i>
        <span>字体大小</span>
      </p>
    </div>
    <div :class="!catlog_show ? 'unread_catbox' : 'read_catbox'">
      <p class="catbox_title">目录</p>
      <span
        class="iconfont icon-guanbi catbox_close"
        @click="show_catlog"
      ></span>
      <span class="catbox_arrow"></span>
      <div class="catbox_list">
        <div
          v-for="volume in chapterData"
          :key="volume.id || 1"
          class="volume-container"
        >
          <div class="volume-title" v-if="volume.chapter_name">
            {{ volume.chapter_name }}
          </div>
          <div class="chapter-list" v-if="volume.directory">
            <div
              class="item"
              v-for="chapter in volume.directory"
              @click="read_book(chapter.page)"
              :key="chapter.id"
            >
              <div class="name" ellipsis>
                {{ chapter.title }}
              </div>
              <div class="cost" v-if="chapter.is_pay === 1">
                {{ chapter.is_order ? "已付费" : "付费" }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="font_size" v-if="font_type">
      <span
        @click.stop="font_size(16)"
        :class="font_big === 16 ? 'font_bc' : ''"
        >小</span
      >
      <span
        @click.stop="font_size(18)"
        :class="font_big === 18 ? 'font_bc' : ''"
        >中</span
      >
      <span
        @click.stop="font_size(20)"
        :class="font_big === 20 ? 'font_bc' : ''"
        >大</span
      >
    </div>
  </div>
</template>
<script>
import { books_directory, favorites } from "@/https/read_books.js";
import { Booksdelete } from "@/https/mine_center.js";

export default {
  props: {
    options: {
      type: Object,
    },
    is_collection: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      catlog_show: false,
      gridData: [],
      chapter_list: [],
      len: null,
      remainder: null,
      font_big: 18,
      font_type: false,
      chapterData: [], //章节数据包含分卷
    };
  },
  methods: {
    // 目录信息
    show_catlog() {
      this.catlog_show = !this.catlog_show;
      let pramas = {
        books_id: this.$route.query.id,
      };
      books_directory(pramas).then((res) => {
        if (res.code === 0) {
          let data = res.data;
          this.chapterData = res.data || [];
          let chapter_list = [];
          if (data && data.length) {
            data.forEach((item, index) => {
              chapter_list.push(...item.directory);
            });
          }
          this.chapter_list = chapter_list;
          this.len = Math.floor(this.chapter_list.length / 3);
          this.remainder = this.chapter_list.length % 3;
        } else {
          this.$message.warning(res.msg);
        }
      });
    },
    // 加入书架、移除书架
    add_brack() {
      let pramas = {
        books_id: Number(sessionStorage.getItem("book_id")),
        section_id: Number(sessionStorage.getItem("section_id")),
      };
      if (!this.is_collection) {
        favorites(pramas).then((res) => {
          if (res.code === 0) {
            this.$message.success("收藏成功");
            this.$emit("change_col", true);
          } else {
            this.$message.warning(res.msg);
          }
        });
      }
      // else{
      //   let props={
      //     string:Number(sessionStorage.getItem("book_id"))
      //   }
      //   Booksdelete(props).then(res=>{
      //     if(res.code===0){
      //       this.$message.success("移出书架成功")
      //       this.$emit('change_col',false)
      //     }
      //   })
      // }
    },
    // 书籍信息
    book_info() {
      this.$router.push({
        path: "/show_book",
        query: {
          id: this.$route.query.id,
        },
      });
    },
    // 阅读
    read_book(page) {
      this.$emit("read_book", page);
      this.catlog_show = !this.catlog_show;
    },
    // 字体
    font_size(num) {
      this.$store.commit("set_font_size", num);
      this.font_big = num;
      this.font_type = false;
    },
  },
  mounted() {
    let body_dom = document.getElementsByTagName("body")[0];
    body_dom.addEventListener("click", () => {
      this.font_type = false;
      this.catlog_show = false;
    });
  },
  created() {
    this.font_big = Number(sessionStorage.getItem("font_size"));
  },
};
</script>
<style lang="scss" scoped>
.read_left {
  border-radius: 5px;
  width: 60px;
  height: 280px;
  font-size: 12px;
  color: #666;
  text-align: center;
  background-color: rgba(248, 243, 233, 1);
  left: calc(50% - 600px);
  // left: -100px;
  // left: 0;
  position: fixed;
  border: 1px solid rgba(228, 228, 228, 1);
  z-index: 99;

  > div:nth-of-type(1) {
    width: 100%;
    height: 100%;

    > p {
      height: calc(100% / 4);
      padding: 5px;
      border-top: 1px solid rgba(228, 228, 228, 1);
      cursor: pointer;
    }

    .brack {
      height: 70px;
      line-height: 70px;
      margin: 0 !important;
      padding: 0 !important;
      cursor: inherit;

      > span {
        line-height: 70px;
        margin: 0;
        color: #999;
      }
    }
  }

  border-radius: 5px;

  i {
    font-size: 32px;
  }

  span {
    margin-top: 5px;
  }

  & p:nth-of-type(1) {
    border-top: none;
  }

  .read_catbox {
    color: #333;
    width: 990px;
    height: 650px;
    background-color: #fff;
    // position: fixed;
    position: absolute;
    // top: 81px;
    top: 0;
    // left: calc((100vw - 1200px)/2 + 96px);
    left: 103px;
    border-top: 1px solid rgba(228, 228, 228, 1);
    box-shadow: 5px 5px 10px rgba(228, 228, 228, 1);
    border-radius: 5px;
    padding: 20px 10px 10px 10px;
    z-index: 90;

    .catbox_title {
      text-align: left;
      font-weight: bold;
      font-size: 16px;
      padding: 5px 0;
      // height: 35px;
      // line-height: 35px;
    }

    .catbox_close {
      position: absolute;
      top: 20px;
      right: 20px;
      font-size: 12px;
      color: #333;
      cursor: pointer;
    }

    .catbox_close:hover {
      color: #409eff;
    }

    .catbox_arrow {
      width: 0;
      height: 0;
      border-top: 12px solid transparent;
      border-bottom: 12px solid transparent;
      border-right: 15px solid #fff;
      // position: fixed;
      position: absolute;
      z-index: 101;
      // top: 100px;
      top: 20px;
      // left: calc((100vw - 1005px) / 2 - 15px);
      left: -10px;
    }

    .catbox_list {
      width: 100%;
      height: calc(100% - 30px);
      overflow-y: scroll;
      font-size: 14px;
      color: #333;

      .volume-container {
        .volume-title {
          padding-left: 15px;
          text-align: left;
          color: #333;
          font-size: 16px;
          line-height: 3em;
        }

        .chapter-list {
          display: flex;
          flex-wrap: wrap;

          .item {
            display: flex;
            width: 33.33333%;
            padding: 15px;
            justify-content: space-between;
            border-bottom: 1px solid #e4e4e4;
            white-space: nowrap;
            cursor: pointer;

            .name {
              flex-grow: 2;
              padding: 0 15px;
              text-align: left;
            }

            .cost {
              background: #fc6c28;
              color: #fff;
              white-space: nowrap;
              padding: 2px 8px;
              border-radius: 4px;
            }
          }
        }
      }
    }

    .catbox_list::-webkit-scrollbar-track {
      // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
      background-color: #f2f2f2;
    }

    .catbox_list::-webkit-scrollbar {
      width: 5px;
      height: 10px;
      background-color: #b5b1b1;
    }

    .catbox_list::-webkit-scrollbar-thumb {
      border-radius: 10px;
      // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #b5b1b1;
    }
  }

  .unread_catbox {
    display: none;
  }
}

.font_size {
  width: 184px;
  height: 50px;
  position: absolute;
  display: flex;
  right: -200px;
  bottom: 16px;
  background: #ffffff;
  box-shadow: 0 0 5px #f5f5f5;
  padding: 5px;
  align-items: center;
  border-radius: 4px;

  > span {
    display: flex;
    height: 28px;
    line-height: 40px;
    text-align: center;
    margin: 0;
    justify-content: center;
    align-items: center;
    margin: 0 4px;
    border: 1px solid #eeeeee;
    cursor: pointer;
    padding: 8px 18px;
    border-radius: 14px;

    &:hover {
      background: #f5f5f5;
      color: #409eff;
    }
  }
}

.font_size::before {
  position: absolute;
  // border-left: 6px solid transparent;
  border-right: 9px solid #ffffff;
  border-bottom: 9px solid transparent;
  border-top: 9px solid transparent;
  content: " ";
  display: block;
  width: 0;
  height: 0;
  top: 50%;
  left: -9px;
  transform: translateY(-50%);
}

.font_bc {
  background: #cacaca !important;
  color: #409eff !important;
}

.cost_money {
  background: #fc6c28;
  color: #ffffff;
  padding: 2px 8px;
  border-radius: 4px;
}

.catbox_list {
  td {
    position: relative;

    span {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      margin-top: 0;
    }
  }
}

.menu_box {
  p:hover {
    background: rgba(0, 0, 0, 0.03);
  }
}
</style>
