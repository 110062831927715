<template>
    <div class="read_right">
        <div class="menu_box" @click="shouAction(1)">
            <img src="./../../assets/images/read/ticket.png" alt="">
            <div class="name">推荐票</div>
        </div>
        <div class="menu_box" @click="shouAction(2)">
            <img src="./../../assets/images/read/reward.png" alt="">
            <div class="name">打赏</div>
        </div>
        <div class="menu_box noborder" @click="back_top">
            <img class="toTop" src="./../../assets/images/read/backtop.png" alt="">
        </div>
<!--        <div class="menu_box">-->
<!--            <p flex column @click="get_commond_sec()">-->
<!--                <i class="iconfont icon-wodepinglun"></i>-->
<!--                <span>评论</span>-->
<!--            </p>-->
<!--            <p flex column center @click="back_top">-->
<!--                <i class="iconfont icon-huidaodingbu"></i>-->
<!--            </p>-->
<!--        </div>-->
<!--        <div :class="!read_combox ? 'read_uncombox' : 'read_combox'">-->
<!--            <span class="iconfont icon-guanbi combox_close" @click="show_combox"></span>-->
<!--            <span class="right_arrow "></span>-->

<!--            <div class="combox_list">-->
<!--                <userComment-->
<!--                    :options="item"-->
<!--                    :is_author="is_author"-->
<!--                    v-for="item in comment_list"-->
<!--                    :key="item.id"-->
<!--                    @author_replay_com="author_replay_com"-->
<!--                ></userComment>-->
<!--                <p>已经到底啦~</p>-->
<!--            </div>-->
<!--            <div class="combox_bottom">-->
<!--                <commentInput-->
<!--                    @up_comment="up_comment"-->
<!--                    :options="options"-->
<!--                ></commentInput>-->
<!--            </div>-->
<!--        </div>-->

        <el-dialog
            :append-to-body="true"
            :visible.sync="showModal"
            class="read-modal"
            width="340px">
            <throwTicket v-if="showModalType === 1"></throwTicket>
            <rewardGift v-else-if="showModalType === 2"></rewardGift>
        </el-dialog>
    </div>
</template>
<script>
import userComment from "@/components/show_book/left/user_comment";
import commentInput from "@/components/show_book/right/commet_input";
import throwTicket from "@/components/show_book/right/throw_ticket";
import rewardGift from "@/components/show_book/right/reward_gift";
import {books_review, add_review} from "@/https/read_books.js"

export default {
    components: {
        userComment,
        commentInput,
        throwTicket,
        rewardGift
    },
    data() {
        return {
            read_combox: false,
            comment_list: [],
            options: {
                input_value: "",
            },
            is_author: false,
            showModal: false,
            showModalType: 1, //1展示推荐票 2展示道具
        };
    },
    mounted() {
        // widow.location.hash = "/";
        // document.getElementsByTagName('body')[0].addEventListener("click",()=>{
        //     this.read_combox=false
        //   })
    },
    created() {
        // this.get_commond_info()
    },
    methods: {
        // 返回顶部
        back_top() {
            document.documentElement.scrollTop = 0
        },
        shouAction(type){
            this.showModal = true;
            this.showModalType = type;
        },
        // 打开\关闭评论
        show_combox() {
            this.read_combox = !this.read_combox;
        },
        // 发布评论
        up_comment() {
            let params = {
                books_id: Number(sessionStorage.getItem("book_id")),
                section_id: Number(sessionStorage.getItem("section_id")),
                content: this.options.input_value
            }
            add_review(params).then((res) => {
                if (res.code === 0) {
                    this.get_commond_info()
                }
            })
        },
        // 作者回复
        author_replay_com(obj, id) {
            obj.id = 1;
            this.comment_list[id - 1].com_list.push(obj);
        },
        // 获取评论列表
        get_commond_info() {
            let pramas = {
                id: Number(sessionStorage.getItem("book_id")),
                section_id: Number(sessionStorage.getItem("section_id"))
            }
            books_review(pramas).then((res) => {
                if (res.code === 0) {
                    this.comment_list = res.data.review_content
                    // this.page.total=res.total
                    if (this.had_login()) {
                        res.data.author_id === Number(JSON.parse(sessionStorage.getItem("person_info")).id) ? this.is_author = true : this.is_author = false
                    }
                }
            })
        },
        get_commond_sec() {
            this.read_combox = !this.read_combox;
            this.get_commond_info()
        }
    }
};
</script>
<style lang="scss">
.read_right {
    border-radius: 5px;
    width: 87px;
    height: 261px;
    font-size: 12px;
    color: #666;
    text-align: center;
    background-color: rgba(248, 243, 233, 1);
    position: fixed;
    bottom: 10px;
    // right: 0;
    right: calc(50% - 600px);
    // right: -100px;
    // position: fixed;
    z-index: 10;
    border: 1px solid rgba(228, 228, 228, 1);

    i {
        font-size: 32px;
    }

    span {
        margin-top: 5px;
    }

    p {
        cursor: pointer;
        height: calc(100% / 2);
        padding: 5px;
        border-top: 1px solid rgba(228, 228, 228, 1);
    }

    & p:nth-of-type(1) {
        border-top: none;
    }

    .read_uncombox {
        display: none;
    }

    .read_combox {
        color: #333;
        text-align: left;
        width: 990px;
        height: 775px;
        background-color: #fff;
        z-index: 100;
        // position: fixed;
        position: absolute;
        bottom: 0;
        right: 104px;
        // right: 104px;
        // right: calc((100vw - 1005px) / 2);
        padding: 20px 10px 20px 30px;
        border-radius: 5px;
        // overflow: scroll;
        border: 1px solid rgba(228, 228, 228, 1);

        .combox_close {
            position: absolute;
            top: 20px;
            right: 20px;
            font-size: 14px;
            color: #333;
            cursor: pointer;
        }

        .combox_close:hover {
            color: #409eff;
        }

        .right_arrow {
            width: 0;
            height: 0;
            border-top: 20px solid transparent;
            border-bottom: 20px solid transparent;
            border-left: 15px solid #fff;
            // position: fixed;
            position: absolute;
            z-index: 101;
            bottom: 80px;
            // bottom: 30px;
            right: -15px;
            // right: calc((100vw - 1005px) / 2 - 10px);
        }

        .combox_list {
            margin-top: 30px;
            height: 83%;
            overflow: scroll;

            .user_comment {
                width: 97%;
            }

            & .user_comment:nth-of-type(1) {
                border-top: none;
            }

            > p {
                text-align: center;
                width: 100%;
                height: 50px;
                line-height: 50px;
                color: #999;
                border-top: 1px dotted rgba(228, 228, 228, 1);
            }
        }

        .combox_bottom {
            margin: 0 auto;
            background-color: #fff;
            width: 100%;
            height: 130px;
            position: absolute;
            bottom: 0;
            left: 0;

            .book_comment_con {
                padding: 0 20px 5px 30px;
            }
        }
    }

    .combox_list::-webkit-scrollbar-track {
        // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        background-color: #f2f2f2;
    }

    .combox_list::-webkit-scrollbar {
        width: 5px;
        height: 10px;
        background-color: #b5b1b1;
    }

    .combox_list::-webkit-scrollbar-thumb {
        border-radius: 10px;
        // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #b5b1b1;
    }
}

.read_right{
    .menu_box {
        cursor: pointer;
        padding: 15px 0;
        border-bottom: 1px solid #999999;
        p:hover {
            background: rgba(0, 0, 0, 0.03);
        }
        &>img{
            width: 40px;
            &.toTop{
                width: 30px;
                margin-top: 10px;
            }
        }
        .name{
            margin-top: 6px;
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;
            color: #363636;
        }
        &.noborder{
            border: none;
        }
    }
}
.read-modal{
    .el-dialog__header{
        display: none;
    }
}
</style>
