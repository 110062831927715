<template>
  <div class="out_container">
    <topHeader></topHeader>
    <div class="read_book">
      <div class="container read_container">
        <div class="container read_book_container" flex center>
          <div class="read_content">
            <leftMenu
              :options="options_left"
              :is_collection="collection"
              @change_col="change_col($event)"
              @read_book="get_content($event)"
            ></leftMenu>
            <div
              class="read_content_top"
              flex
              column
              between
              align
              v-if="books_detail.page === 1"
            >
              <img :src="books_detail.image" alt="" v-if="books_detail.image" />
              <img src="../../assets/images/default.png" alt="" v-else />
              <p class="read_name">{{ books_detail.book_name }}</p>
              <p class="read_author">{{ books_detail.author }} 著</p>
              <div flex around>
                <p flex column>
                  <span>{{ books_detail.cate[0] }}</span>
                  <span>作品类型</span>
                </p>
                <p flex column>
                  <span>{{ books_detail.create_time }}</span>
                  <span>上架时间</span>
                </p>
                <p flex column>
                  <span>{{ books_detail.words }}</span>
                  <span>{{ books_detail.serial_status }}(字)</span>
                </p>
              </div>
              <p class="read_copyright">版权所有，盗版必究</p>
            </div>
            <div class="read_content_middle">
              <readCon :options="options"></readCon>
            </div>
            <div class="read_content_bottom" flex between align>
              <p @click="pre_chapter" v-if="books_detail.page > 1">上一章</p>
              <!-- 判断是否为第一章，第一章不能点击上一章并设置为#999颜色 -->
              <p @click="to_catlog">目录</p>
              <p
                @click="next_chapter"
                v-if="books_detail.max_page > books_detail.page"
              >
                下一章
              </p>
              <!-- 判断是否为最后一章，第一章不能点击下一章并设置为#999颜色 -->
            </div>
            <rightMenu></rightMenu>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import leftMenu from "@/components/read_book/left_menu.vue";
import rightMenu from "@/components/read_book/right_menu.vue";
import readCon from "./read_con";
import topHeader from "@/components/main/top-header.vue";
import { books_directory, books_read } from "@/https/read_books.js";

export default {
  components: {
    leftMenu,
    rightMenu,
    readCon,
    topHeader,
  },
  data() {
    return {
      options_left: {
        brack_flag: false,
        id: 1,
      },
      book_obj: {
        id: 1,
        img: require("@/assets/images/book_bc.jpg"),
        author: "作者",
        time: "2020-10-01",
        classify: "古代言情",
        status: "连载中",
        words: "222223",
        book_name: "作品名称",
      },
      read_content: "当前章节内容",
      options: {
        // is_free:true,
        is_free: false,
        content: "章节内容章节内容章节内容章节内容章节内容",
        page: "",
        title: "",
        loading: false,
      },
      curr_id: this.$route.query.id, //当前章节id
      books_detail: "",
      section_page: null,
      collection: false,
      muluList: [],
    };
  },
  watch: {},
  created() {
    this.aviod_id();
  },
  mounted() {
    this.get_content();
    this.getMulu();
    if (this.$route.query.id) {
      sessionStorage.setItem("book_id", this.$route.query.id);
    }
    document.oncontextmenu = new Function("event.returnValue=false");
    document.onselectstart = new Function("event.returnValue=false");
  },
  beforeDestroy() {
    sessionStorage.removeItem("section_page");
    sessionStorage.removeItem("section_id");
    document.oncontextmenu = new Function("event.returnValue=true");
    document.onselectstart = new Function("event.returnValue=true");
  },
  methods: {
    getMulu() {
      books_directory({
        books_id: this.$route.query.id,
      }).then((res) => {
        let list = [];
        (res.data || []).forEach((item) => {
          if (item.directory && item.directory.length) {
            list = list.concat(item.directory);
          }
        });
        this.muluList = list;
      });
    },
    // 获取书籍和章节id
    aviod_id() {
      let page = this.$route.query.page; //直接指定章节跳转 时获取路由传参（page可自己定义）
      if (page) {
        this.section_page = page;
        sessionStorage.setItem("section_page", page);
      } else {
        let section_page = sessionStorage.getItem("section_page"); //读取缓存
        if (section_page) {
          this.section_page = Number(section_page); //刷新后读取缓存 定位章节
        } else {
          this.section_page = 1; //初次进入页面默认第一章
          sessionStorage.setItem("section_page", 1);
        }
      }
    },
    get_content(page) {
      // 根据章节id从后台读取文件内容
      this.options.loading = true;
      let params = {
        // books_id:1,
        books_id: Number(this.$route.query.id),
        page: this.section_page,
      };
      if (page) {
        params.page = page;
      }
      books_read(params)
        .then((res) => {
          if (res.code === 0) {
            document.documentElement.scrollTop = 0;
            this.books_detail = res.data;
            this.collection = res.data.is_col === 1 ? true : false;
            res.data.is_order === 1
              ? (this.options.is_free = true)
              : (this.options.is_free = false);
            this.options = res.data;
            sessionStorage.setItem("section_id", res.data.section_id);
            if (page) {
              sessionStorage.setItem("section_page", page);
              this.section_page = page;
              this.$router.push({
                path: "/read_book",
                query: {
                  id: Number(sessionStorage.getItem("book_id")),
                  page: Number(sessionStorage.getItem("section_page")),
                },
              });
            } else {
              sessionStorage.setItem("section_page", this.section_page);
            }
          } else {
            this.$message.warning(res.msg);
          }
        })
        .finally(() => (this.options.loading = false));
    },
    to_catlog() {
      // console.log(this.curr_id);
      this.$router.push({
        path: "/show_book/book_catlog",
        query: {
          id: Number(sessionStorage.getItem("book_id")),
        },
      });
    },
    pre_chapter() {
      if (this.section_page > 1) {
        let index = this.muluList.findIndex(
          (it) => it.page === Number(this.section_page)
        );
        if (index === 0) return;
        let lastPage = this.muluList[index + -1].page;
        this.section_page = lastPage;
        document.documentElement.scrollTop = 0;
        this.$router.push({
          path: "/read_book",
          query: {
            id: Number(sessionStorage.getItem("book_id")),
            page: lastPage,
          },
        });
        this.get_content();
      }
    },
    next_chapter() {
      let index = this.muluList.findIndex(
        (it) => it.page === Number(this.section_page)
      );
      let nextPage = this.muluList[index + 1].page;
      this.section_page = nextPage;
      document.documentElement.scrollTop = 0;
      this.$router.push({
        path: "/read_book",
        query: {
          id: Number(sessionStorage.getItem("book_id")),
          page: this.section_page,
        },
      });
      this.get_content();
    },
    change_col(type) {
      this.collection = type;
    },
  },
};
</script>

<style lang="scss" scoped>
.read_book {
  min-width: 1200px;
  // width: 100%;
  // margin-top: 20px;
  padding-top: 20px;
  background-color: rgba(237, 231, 218, 1);
  position: relative;

  .read_book_top {
    width: 100%;

    .top_header {
      background-color: rgba(248, 243, 233, 1);
    }
  }

  .container {
    min-height: calc(100vh - 80px);
    .read_content {
      height: 100%;
      width: 83.3%;
      margin: 0 auto;
      // position: relative;
      color: #333;
      // overflow:auto;
      .read_content_top {
        width: 100%;
        height: 700px;
        border: 1px solid rgba(228, 228, 228, 1);
        border-radius: 5px;
        background-color: rgba(248, 243, 233, 1);
        text-align: center;
        padding: 50px 0 20px;
        font-size: 18px;
        margin-bottom: 20px;

        img {
          width: 200px;
          height: 280px;
        }

        .read_name {
          font-size: 18px;
        }

        .read_author {
          font-size: 14px;
        }

        div {
          width: 75%;
          line-height: 35px;

          > p {
            font-size: 18px;
          }

          & p span:nth-of-type(2) {
            font-size: 14px;
            color: #999;
          }
        }

        .read_copyright {
          color: #999;
          font-size: 16px;
        }
      }

      .read_content_middle {
        width: 100%;
        // height: 200px;
        font-size: 18px;
        margin: 0 0 20px 0;
        padding: 50px 100px 30px;
        border: 1px solid rgba(228, 228, 228, 1);
        border-radius: 5px;
        background-color: rgba(248, 243, 233, 1);
      }

      .read_content_bottom {
        width: 100%;
        height: 90px;
        border: 1px solid rgba(228, 228, 228, 1);
        border-radius: 5px;
        background-color: rgba(248, 243, 233, 1);
        line-height: 45px;
        display: flex;
        justify-content: center;

        p {
          width: calc(100% / 3);
          text-align: center;
          font-size: 18px;
          cursor: pointer;
          border-left: 1px solid rgba(153, 153, 153, 1);
        }

        p:hover {
          background: rgba(0, 0, 0, 0.03);
        }

        p:first-child {
          border-left: 0px !important;
        }

        // p:last-child{
        //   border-left: 1px solid rgba(153, 153, 153, 1);
        // }
      }
    }
  }
}

// /deep/.top_header{
//   background-color: rgba(255,255,255,.4) !important;
// }
.read_book_container {
  // position: relative;
  // overflow:hidden;
}

@media screen and(max-width: 1200px) {
  .read_container {
    overflow: hidden;
  }
  .read_book_container {
    overflow-x: hidden;
    overflow-y: hidden;
    position: relative;
    background-color: rgba(237, 231, 218, 1);

    .read_left {
      position: absolute;
      left: 0;
      top: 0;
    }

    .read_right {
      position: absolute;
      right: 0;
      bottom: 10px;
    }
  }
  // .inner-read_book_container::-webkit-scrollbar {
  //   display: none;
  // }
}

/deep/ .top_header {
  background: #f8f3e9 !important;
}

.out_container {
  background: #f8f3e9 !important;
}
</style>
